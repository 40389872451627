<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">홈</a></li>
                        <li class="breadcrumb-item"><a href="javascript:">관리자모드</a></li>
                    </ol>
                </nav>
                <h2 class="page-title">사용자 관리</h2>
                <div class="page-title-bottom"></div>
            </div>
        </div>

        <!-- page-body -->
        <div class="page-body page-component">
            <div id="contents">
                <div class="inner">
                    <div class="top_area">
                        <p>
                            Total <span>{{ items.length }}</span
                            >건
                        </p>
                    </div>
                    <div class="table_wrap type1">
                        <table>
                            <colgroup>
                                <col style="width: 4%" />
                                <col style="width: 24%" />
                                <col style="width: 38%" />
                                <col style="width: 12%" />
                                <col style="width: 22%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>아이디</th>
                                    <th>권한정보</th>
                                    <th>사용여부</th>
                                    <th>등록일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in items" class="member-item" :key="index" :product="items" @click="goEdit(item.mno)">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.memberId }}</td>
                                    <td>{{ getDcdName(item.memberTypeDcd) }}</td>
                                    <td v-if="item.useYn == 'Y'">사용</td>
                                    <td v-else>미사용</td>
                                    <td>{{ item.createDt }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--<nav class="pagination">
                        <ol class="page-list">
                            <li class="page-item"><a href="javascript:" class="page-link" aria-label="Previous"><i class="icon-prev" aria-hidden="true"></i><span class="sr-only">Previous</span></a></li>-->
                    <!--<li class="page-item is-active"><a class="page-link" href="javascript:">1</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">5</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">6</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">7</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">8</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">9</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:">10</a></li>
                            <li class="page-item"><a href="javascript:" class="page-link" aria-label="Next">
                                <i class="icon-next" aria-hidden="true"></i><span class="sr-only">Next</span></a>
                            </li>
                        </ol>
                    </nav>-->
                </div>
            </div>
            <!-- page-aside -->
            <aside class="page-aside">
                <!--<nav class="snb">
                    <ol class="snb-list">
                        <li
                            v-for="(board, index) in boardTypes"
                            class="snb-item"
                            :class="{'is-active': board.dcd === filters.memberTypeDcd}"
                            @click="selectBoardType(board.dcd)"
                            :key="index">
                            <a href="javascript:" class="snb-link">
                                {{ board.name }}
                                <span v-if="board.dcd === filters.memberTypeDcd && paging.totalCount > 0" class="sub">{{ paging.totalCount }}</span>
                            </a>
                        </li>
                    </ol>
                </nav>-->
            </aside>

            <!-- page-contents -->
            <div class="page-contents">
                <!--<nav class="page-tabs">
                    <ul class="tab-list">
                        <li
                            v-for="(board, index) in boardGbs"
                            class="tab-item"
                            :class="{'is-active': board.dcd === conditionalFilters.boardGbDcd}"
                            @click="selectBoardGb(board.dcd)"
                            :key="index">
                            <a href="javascript:" class="tab">{{ board.name }}</a>
                        </li>
                    </ul>
                </nav>-->
                <!-- pagination -->
                <CommonPagination :paging="paging" :page-func="pageFunc" />
                <!-- board-list-container -->
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <div v-if="session.name !== null && session.manager && showButton" class="page-bottom bottom-fixed" style="bottom: 16px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="goEdit(0)"><span class="text">등록</span></button>
            </div>
        </div>
    </main>
</template>
<script>
import {computed, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getValueByQuery} from '@/assets/js/route.utils';
import CommonPagination from '@/components/common/CommonPagination';
import {getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/common.utils';
//import {getBoardList} from '@/assets/js/api.service.js';
//import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {useStore} from 'vuex';
import {domainCode, getDcdName} from '@/assets/js/domain.code';
import ApiService from '@/assets/js/api.service';
//import {isSuccess} from '@/assets/js/common.utils';
//import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'Manager',
    components: {CommonPagination},
    //data: function () {
    //    return {
    //        doItem: '',
    //        showModal: false,
    //    };
    //},
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const session = computed(() => store.state.auth.session);
        // const dcds = computed(() => store.state.common.dcds);

        const items = ref([]);

        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 20,
            totalCount: 0,
        });
        /*
        const filters = reactive({
            memberTypeDcd: getValueByQuery(route.query, 'memberTypeDcd', false, null),
        });

        const conditionalFilters = reactive({
            boardGbDcd: getValueByQuery(route.query, 'boardGbDcd', false, null),
        });
*/
        /*
        const boardTypes = [
            {name: '전체', dcd: null},
            {name: '공지', dcd: domainCode.BOARD_TYPE_NOTICE},
            {name: '사업공지', dcd: domainCode.BOARD_TYPE_BUSINESS},
            {name: '자료실', dcd: domainCode.BOARD_TYPE_REFERENCE},
            {name: '보도자료', dcd: domainCode.BOARD_TYPE_REPORT},
        ];
/*
        const boardGbs = [
            {name: '전체', dcd: null},
            {name: '대구', dcd: domainCode.BOARD_GB_DAEGU},
            {name: '제주', dcd: domainCode.BOARD_GB_JEJU},
        ];
/*
        const selectBoardType = dcd => {
            router.push({query: {...filters, memberTypeDcd: dcd}});
        };

        const selectBoardGb = dcd => {
            router.push({query: {...filters, boardGbDcd: dcd}});
        };
*/
        const pageFunc = pageNo => {
            router.push({query: {pageNo: pageNo}});
        };

        const getMemberList = () => {
            ApiService.get('/v1/members', 'list').then(res => {
                //console.log(res);
                //if (isSuccess(res)) {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    //paging.totalCount = getTotalCount(res);
                }
                //await setBeforeRoute({name: getMenuName(route.path), uri: route.path});
                //await
                //}
            });
        };
        //const renderItems = computed(() => paginate(items.value, paging.pageNo, paging.pageSize));
        /*
        const getBoardNoticeList = () => {
            const params = {...paging, noticeYn: 'Y'};
            if (params.memberTypeDcd === domainCode.BOARD_TYPE_BUSINESS) {
                /*Object.keys(conditionalFilters).forEach(key => {
                    params[key] = conditionalFilters[key];
                });*/
        /*       }

            getBoardList(params).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    paging.totalCount = getTotalCount(res);
                }
            });
        };
*/
        // notice => 공지사항
        watch(
            () => route.query,
            () => {
                if (route.name === 'Manager') {
                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                    console.log(route);
                    //filters.memberTypeDcd = getValueByQuery(route.query, 'memberTypeDcd', false, null);
                    //conditionalFilters.boardGbDcd = getValueByQuery(route.query, 'boardGbDcd', false, null);

                    items.value = [];
                    paging.totalCount = 0;
                    getMemberList();
                    // 실행 api 실행 됨
                    //getBoardNoticeList();
                }
            },
        );

        const showButton = ref(true);
        // const toggleButtonHandler = () => {
        //     const scrollTop = window.scrollY;
        //     const innerHeight = window.innerHeight;
        //     const scrollHeight = document.body.scrollHeight;
        //     const isShow = !(scrollTop + innerHeight >= scrollHeight);
        //     if (showButton.value !== isShow) showButton.value = isShow;
        // };
        //
        // onMounted(() => {
        //     document.addEventListener('scroll', toggleButtonHandler);
        // });
        //
        // onUnmounted(() => {
        //     document.removeEventListener('scroll', toggleButtonHandler);
        // });
        /*
        const goEdit = e => {
            if (e.ctrlKey) {
                window.open('/board/notice/0');
            } else {
                router.push({name: 'NoticeEdit', params: {mno: 0}});
            }
        };
*/

        const goEdit = mno => {
            if (!session.value.manager) return;
            //getMemberData(mno);
            router.push({name: 'MemberEdit', params: {mno: mno}});
        };
        if (!session.value.manager) {
            router.push({name: 'Main'});
        } else {
            getMemberList();
        }

        //getBoardNoticeList();

        return {
            session,
            //filters,
            //conditionalFilters,
            //boardTypes,
            //boardGbs,
            items,
            //renderItems,
            paging,
            showButton,
            //selectBoardType,
            //selectBoardGb,
            getDcdName,
            pageFunc,
            goEdit,
            timestampToDateFormat,

            domainCode,
        };
    },
};
</script>
